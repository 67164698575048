import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import KimLogo from './assets/kim-logo-transparent.png';
import axiosInstance from './client/axios.js';
import HelpdeskFrame from './assets/helpdesk_frame.webp';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get('redirectURL');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = async () => {
    setIsLoggingIn(true);
    try {
      const email = document.getElementById('email').value;
      const password = document.getElementById('password').value;

      // Get all search params
      const allParams = new URLSearchParams(searchParams);

      if (!allParams.get('redirectPlatform')) {
        allParams.append('redirectPlatform', 'crm');
      }

      const response = await axiosInstance.post(
        `/account/login?${allParams.toString()}`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const redirectURL =
        response.data?.shopifyRedirectURL || response.data.redirectURL;
      window.location.href = redirectURL;
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'An error occurred during login. Please try again.'
      );
      console.log('Error during login:', error);
    } finally {
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const response = await axiosInstance.get(
        `/account/login-session-check?redirectURL=${redirectURL}`
      );
      console.log(response.data, 'login session check response');
      if (response.data.redirectURL) {
        window.location.href = response.data.redirectURL;
      }
    })();
    setIsLoading(false);
  }, [redirectURL]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div
    style={{ 
      background: `url(${HelpdeskFrame})`,
      backgroundSize: 'cover',
    }}
      className="flex justify-center items-center min-h-screen bg-center p-4 relative bg-no-repeat backdrop-blur-[12px]"
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black/70"></div>
      
      <div className="form px-4 py-12 sm:p-12 rounded-md w-full max-w-md bg-white shadow-xl relative z-10">
        <img src={KimLogo} alt="Kim Logo" className="w-1/4 sm:w-1/4 mb-4" />
        <h2
          className="text-xl sm:text-2xl font-semibold mb-4 sm:my-8 text-left"
          style={{ color: '#272727' }}
        >
          Welcome back!
        </h2>
        <form className="flex flex-col space-y-4">
          <input
            type="text"
            id="email"
            placeholder="Your Email"
            className="py-2 px-4 border border-[#d9d9d9] rounded-lg placeholder-[#948F8F]"
          />
          <input
            type="password"
            id="password"
            placeholder="Password"
            className="py-2 px-4 border border-[#d9d9d9] rounded-lg placeholder-[#948F8F]"
          />
          {errorMessage && (
            <div className="text-red-500 text-center text-sm">{errorMessage}</div>
          )}
          <button
            type="button"
            onClick={handleLogin}
            disabled={isLoggingIn}
            className="bg-[#fabf01] text-[#272727] px-3 py-2 font-medium rounded-lg shadow-lg flex items-center justify-center"
          >
            {isLoggingIn ? (
              <>
                <div className="loader mr-2 w-4 h-4 border-2 border-white border-t-[#085454] rounded-full animate-spin"></div>
                Loggin In...
              </>
            ) : (
              'Login'
            )}
          </button>
        </form>
        <p className="text-gray-600 sm:text-sm text-center mt-6">
          Don&apos;t have an account?{' '}
          <Link to={`/signup${location.search}`} className="text-[#085454] font-medium underline underline-offset-4">
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
