import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import KimLogo from './assets/kim-logo-transparent.png';
import Frame from './assets/Frame.png';
import axiosInstance from './client/axios.js';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get('redirectURL');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = async () => {
    setIsLoggingIn(true);
    try {
      const email = document.getElementById('email').value;
      const password = document.getElementById('password').value;

      // Get all search params
      const allParams = new URLSearchParams(searchParams);

      if (!allParams.get('redirectPlatform')) {
        allParams.append('redirectPlatform', 'crm');
      }

      const response = await axiosInstance.post(
        `/account/login?${allParams.toString()}`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const redirectURL =
        response.data?.shopifyRedirectURL || response.data.redirectURL;
      window.location.href = redirectURL;
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'An error occurred during login. Please try again.'
      );
      console.log('Error during login:', error);
    } finally {
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const response = await axiosInstance.get(
        `/account/login-session-check?redirectURL=${redirectURL}`
      );
      console.log(response.data, 'login session check response');
      if (response.data.redirectURL) {
        window.location.href = response.data.redirectURL;
      }
    })();
    setIsLoading(false);
  }, [redirectURL]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  console.log('here');
  return (
    <div
      style={{ backgroundImage: `url(${Frame})` }}
      className="flex justify-center items-center h-screen bg-cover bg-center"
    >
      <div className="form p-8 rounded-lg max-w-sm w-full bg-white shadow-lg">
        <img src={KimLogo} alt="Kim Logo" className="w-1/3 mx-auto mb-4" />
        <h2
          className="text-2xl font-bold mb-6 text-center"
          style={{ color: '#444444' }}
        >
          Login
        </h2>
        <form className="flex flex-col space-y-4">
          <input
            type="text"
            id="email"
            placeholder="Your Email"
            className="p-2 border border-[#272727] rounded-lg placeholder-[#948F8F]"
          />
          <input
            type="password"
            id="password"
            placeholder="Password"
            className="p-2 border border-[#272727] rounded-lg placeholder-[#948F8F]"
          />
          {errorMessage && (
            <p className="text-red-500 text-sm">{errorMessage}</p>
          )}
          <button
            type="button"
            onClick={handleLogin}
            disabled={isLoggingIn}
            className="bg-[#fabf01] text-[#085454] p-3 font-bold rounded-lg shadow-lg flex items-center justify-center"
          >
            {isLoggingIn ? (
              <>
                <div className="loader mr-2 w-5 h-5 border-2 border-[#085454] border-t-[#fabf01] rounded-full animate-spin"></div>
                Logging In...
              </>
            ) : (
              'Login'
            )}
          </button>
        </form>
        <div className="text-center mt-4">
          Don&apos;t have an account?{' '}
          <Link to={`/signup${location.search}`} className="text-[#085454]">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
